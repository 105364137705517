import '../scss/main.scss';

jQuery(function() {
    Anchors = new Anchors();
    FadeIn = new FadeIn();
    $('body').addClass('js');
});
//-----------------------------------------------------------
// FADEIN
// -----------------------------------------------------------

var FadeIn = function() {
    this.selector = ".js-fadein, .js-fadein-up, .js-fadein-down, .js-fadein-left, .js-fadein-right",
        this.body = $(document.body),
        this.window = $(window),
        this.resizeBound = this.resize.bind(this),
        this.scrollBound = this.scroll.bind(this),
        this.clear(),
        this.parse()
}

FadeIn.prototype.clear = function () {
    this.window.off("resize", this.resizeBound), this.window.off("scroll", this.scrollBound), this.items = [], delete this.item
}
FadeIn.prototype.parse = function (t) {
    t || (t = this.body);
    var i = t.filter(this.selector).add(t.find(this.selector));
    if (i.removeClass("show"), i.length) {
        var e;
        i.each(function (t, i) {
            i = $(i), e = i.hasClass("js-fadein-prio"), this.items.push({
                element: i,
                hold: parseInt(i.attr("data-fadein-hold") || 0),
                prio: e,
                prioSort: e ? 1 : 2,
                top: Number.MAX_VALUE
            })
        }.bind(this)), this.item || (this.window.on("resize", this.resizeBound), this.window.on("scroll", this.scrollBound)), $("img").on("load", this.resizeBound), this.resize()
    }
}

FadeIn.prototype.set = function (t, i, e) {
    var o = '[class*="js-fadein"]',
        n = t.filter(o);
    e && (n = n.add(t.find(o))), n.toggleClass("show", i)
}

FadeIn.prototype.reverse = function (t, i) {
    var e = t.filter(this.selector).add(t.find(this.selector));
    e.addClass("fadeout"), $.isFunction(i) && setTimeout(function () {
        i()
    }, 800)
}

FadeIn.prototype.resize = function (t) {
    clearTimeout(this.resizeTimeout), this.resizeTimeout = setTimeout(function () {
        this.windowHeight = this.window.height(), this.documentHeight = $body.outerHeight() - .3 * this.windowHeight;
        var t, i;
        for (t = 0; t < this.items.length; t++) i = this.items[t], i.top = i.element.offset().top;
        this.items.sortOn("prioSort", "top"), this.item = this.items[0], this.scroll()
    }.bind(this), t ? 100 : 0)
}

FadeIn.prototype.scroll = function (t) {
    if (t || (clearTimeout(this.timeout), delete this.timeout), this.item && !this.timeout) {
        var i = this.window.scrollTop(),
            e = i + this.windowHeight * (t ? .8 : 1);
        if (WIDESCREEN || this.item.top <= e || i + this.windowHeight >= this.documentHeight) {
            this.item.element.addClass("show"), this.items.shift();
            var o = this.items[0];
            if (o) {
                var n = this.item.top < i || this.item.top == o.top ? 0 : 200 + this.item.hold;
                this.item = o, this.timeout = setTimeout(this.scrollBound, n)
            } else this.clear()
        }
    }
}


// // -----------------------------------------------------------
// // LOAD MORE
// // -----------------------------------------------------------
// var LoadMore = function() {
//     this.element = jQuery('.js-LoadMore');
//     if (!this.element.length) return;
//     var loadMoreElements;
//     this.element.each(function(index, item) {
//         var items = jQuery(item);
//         var loadMoreElements = new LoadMoreElements(items);
//     });
// };
// var LoadMoreElements = function(items) {
//     this.element = items;
//     this.items = this.element.find('.js-item');
//     this.text = this.element.attr('data-text');
//     if (this.items.length > 7) {
//         this.element.append(`
//                 <div class="section">
//                     <p class="is-primary is-size-4 is-bold js-btn" >
//                         <span>${this.text}</span>
//                     </p>
//                 </div>
//             `);
//         this.items.hide();
//         this.items.slice(0, 8).show();
//     }
//     this.items.hide();
//     this.items.slice(0, 7).show();
//     this.btn = this.element.find('.js-btn');
//     this.btn.on('click', this.more.bind(this));
// }
// LoadMoreElements.prototype.more = function(e) {
//
//     this.items.filter(':hidden').slice(0, 8).show();
//     if (this.items.length == this.items.filter(':visible').length) {
//         this.btn.hide();
//     }
// };


// -----------------------------------------------------------
// ANCHORS
// -----------------------------------------------------------
var Anchors = function() {
  this.items = [];

	this.parse();
	$window.on('hashchange', this.hashchange.bind(this));
}

Anchors.prototype.parse = function() {
	var anchor;
	jQuery('.js-anchor').each(function(index, item) {
		item = jQuery(item);
		if (!item.data('anchor')) {
			anchor = new Anchor(item);
			item.data('anchor', anchor);
			this.items.push(anchor);
		}
	}.bind(this));

	this.hashchange();
}

Anchors.prototype.hashchange = function(e) {
	var hash = window.location.hash.replace('#', '');
	if (this.hash != hash || !e) {
		this.hash = hash;
		if (hash) {
			var i, item;
			for (i=0; i<this.items.length; i++) {
				item = this.items[i];
				if (item.id == hash) {
					$bodyHtml.stop(true).animate({'scrollTop': item.target.offset().top - 72});
				}
			}
		}
	}
}

// -----------------------------------------------------------
// ANCHOR
// -----------------------------------------------------------
var Anchor = function(element) {
	this.element = element;
	if (!this.element.length) return;
  this.menu = jQuery('#menu');
	this.element.on('click', this.click.bind(this));
	this.id = this.element.find("a").attr('href').split('#').pop();

	this.target = jQuery('#' + this.id);
	this.target.attr('id', 'js-' + this.id);
}
Anchor.prototype.closeMenu = function() {
  if (this.menu.prop("checked") == true) {
      this.menu.prop("checked", false);
  }
}

Anchor.prototype.click = function(e) {
	if (this.id == Anchors.hash) {
		Anchors.hashchange();
	}
  setTimeout(function (){
      this.closeMenu()
  }.bind(this), 800);
}

// Youtube Embed
// var players = document.querySelectorAll('.youtube-player')

// var loadPlayer = function (event) {
//   var target = event.currentTarget
//   var iframe = document.createElement('iframe')
  
//   iframe.height = target.clientHeight
//   iframe.width = target.clientWidth
//   iframe.src = 'https://www.youtube.com/embed/' + target.dataset.videoId + '?autoplay=1'
//   iframe.setAttribute('frameborder', 0)
//   iframe.setAttribute( "allow", "autoplay" )
  
//   target.classList.remove('pristine')
  
//   if (target.children.length) {
//     target.replaceChild(iframe, target.firstElementChild)
//   } else {
//     target.appendChild(iframe)
//   }
// }

// var config = { once: true }

// Array.from(players).forEach(function (player) {
//   player.addEventListener('click', loadPlayer, config)
// })

document.querySelectorAll('.flex-item').forEach(item => {
    item.addEventListener('mouseenter', () => {
        // Add 'flex-item-hovered' to hovered item
        item.classList.add('flex-item-hovered');

        // Add 'flex-item-not-hovered' to siblings
        getSiblings(item).forEach(sib => sib.classList.add('flex-item-not-hovered'));
    });

    item.addEventListener('mouseleave', () => {
        // Remove 'flex-item-hovered' from hovered item
        item.classList.remove('flex-item-hovered');

        // Remove 'flex-item-not-hovered' from siblings
        getSiblings(item).forEach(sib => sib.classList.remove('flex-item-not-hovered'));
    });
});

// Helper function to get siblings of an element
function getSiblings(element) {
    return Array.from(element.parentNode.children).filter(child => child !== element);
}

function heartColor() {
    var element = document.getElementById("heartbutton");
    element.classList.add("heartcolor");
  }

document.addEventListener('DOMContentLoaded', function() {
    // Load YouTube Shorts immediately
    var shorts = document.querySelectorAll('.youtube-short'); // Target only YouTube Shorts
    shorts.forEach(function(target) {
        loadIframe(target); // Call the same function used for loading iframes, passing the current target
    });

    // Setup click event for YouTube Players
    var players = document.querySelectorAll('.youtube-player'); // Target only YouTube Players
    players.forEach(function(player) {
        player.addEventListener('click', function(event) {
            loadIframe(event.currentTarget); // Use the loadIframe function on click
        }, { once: true });
    });
});

function loadIframe(target) {
    var iframe = document.createElement('iframe');

    // Determine the optimal size; adjust as necessary.
    var isShort = target.classList.contains('youtube-short');
    var height = isShort ? 560 : target.clientHeight; // Adjust for YouTube Shorts
    var width = isShort ? 315 : target.clientWidth; // Adjust for YouTube Shorts
    
    iframe.height = height;
    iframe.width = width;
    
    // Retrieve the video ID directly
    var videoId = target.dataset.videoId;
    var embedUrl = 'https://www.youtube.com/embed/' + videoId + (isShort ? '' : '?autoplay=1'); // Adjust URL based on type
    
    iframe.src = embedUrl;
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');
    iframe.setAttribute('allowfullscreen', '');
    
    target.classList.remove('pristine');
    
    // Append the iframe to the target element
    if (target.children.length) {
        target.replaceChild(iframe, target.firstElementChild);
    } else {
        target.appendChild(iframe);
    }
}



